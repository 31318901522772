
import { defineComponent } from 'vue'
import Following from "@/components/Following.vue"

export default defineComponent({
    name: "AboutUs",
    components:{
        Following
    }
})
