import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "following",
  class: "following-container w-full flex items-center justify-center"
}
const _hoisted_2 = { class: "font-semibold text-5xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.count), 1)
  ], 512))
}