
import {defineComponent} from 'vue'

export default defineComponent({
    name:"Following",
    data(){
        return{
            count: 0,
            counting: true,
        }
    },
    mounted(){
        window.addEventListener( 'scroll', () => {
        
            // get counter
            const following = this.$refs.following as HTMLElement
          
            if( following != null){
                // get counter current position
                const posY = following.getBoundingClientRect().top

                // get visible area
                const visibleArea = window.innerHeight

                if( posY+32 < visibleArea ){

                    if (this.counting){
                        this.counting = false
                        
                        let interval = setInterval(() =>{
                            if( this.count < (this.followers as number) ){
                                if( ((this.followers as number) - this.count) > 100){
                                    this.count += 100
                                }
                                else if( ((this.followers as number) - this.count) > 10){
                                    this.count += 10
                                }
                                else if( ((this.followers as number) - this.count)  >= 1){
                                    this.count += 1
                                }                            
                            }else{
                                clearInterval(interval) 
                            }
                        },30)
                    }
                }
            }
        })
        
    },
    props:{
        followers: Number
    }
})
